import React,{ useState, useEffect, useRef }from 'react';
import { Navbar, Container, Row, Col, FormCheck, Form, FormControl, Button, InputGroup, Modal, Table }from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import './App.css';

var jsonData = [], 
maleJsonData = [{'fullname': 'select', 'id': 0}], 
femaleJsonData = [{'fullname': 'select', 'id': 0}],
parentsJsonData = [{'fullname': 'select', 'id': 0}],
commonMemberJsonData = [{'fullname': 'select', 'id': 0}];
const reg1 = /^[0]?[123456789]\d{9}$/;
const reg2 = /^([0|\+[0-9]{1,5})?([0-9][0-9]{9})$/;
const emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function App() {

  useEffect(() => {
    fetchData();
    //localStorage.removeItem('allmemberdata');
  }, []);

  const fetchData = async () => {
    let coord = await fetch('http://shrimalibrahma.thingsofinternet.co.in/mobileapp/index.php/AdminApp/getFormData', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response) => response.json())
    .then(async(responseJson) => {
      //console.log(responseJson);
      setBloodGrpData(responseJson.BloodGroupMaster);
      setMaritalStatusData(responseJson.MaritalStatus);
      setRelationMasterData(responseJson.RelationMaster);
      setGotraMasterData(responseJson.GotraMaster);
      setCountryData(responseJson.country);
      setStateData(responseJson.state);
      setCityData(responseJson.city);
      setShowLoadingModal(false);
      let statedata = responseJson.state.filter((item)=>{
        return item.country_id === '101'
      })
      let citydata = responseJson.city.filter((item)=>{
        return item.state_id === '1'
      })
      let modalrelationdata = responseJson.RelationMaster.filter((item)=>{
        return item.id !== '1'
      })
     // console.log(modalrelationdata);
      setModalRelationMasterData(modalrelationdata)
      //console.log(statedata);
      //console.log(citydata);
      setFHCity(citydata[0].id);
      setfilterdStateData(statedata);
      setFilterdCityData(citydata);
    })
    .catch(error => console.log(error));
  };

  window.onload = () => {
    //console.log('onload');
    localStorage.removeItem('allmemberdata');
  }

  let fileUpload = useRef();
  const [imgurl, setImgurl] = useState(require('./../src/icons/file-upload.png'));
  const [croppedimgurl, setCroppedimgurl] = useState(require('./../src/icons/file-upload.png'));
  const [croppedFHImage,setCroppedFHImage] = useState(require('./../src/icons/file-upload.png'));
  const [croppedFMImage,setCroppedFMImage] = useState(require('./../src/icons/file-upload.png'));
  const [memberimgurl, setMemberImgurl] = useState(require('./../src/icons/file-upload.png'));
  const [membercroppedimgurl, setMemberCroppedimgurl] = useState(require('./../src/icons/file-upload.png'));
  const [viewCropTool, setViewCropTool] = useState(false);
  const [showviewData, setShowViewData] = useState(false)
  const [crops, setCrops] = useState({unit: 'px', x: 52, y: 53, aspect: 14/16, width: 140, height: 160});
  const [memberviewCropTool, setMemberViewCropTool] = useState(false);
  const [membercrops, setMemberCrops] = useState({unit: 'px', x: 52, y: 53, aspect: 14/16, width: 140, height: 160});
  const [imgRef,setImgRef] = useState(null);
  const [memberimgRef,setMemberImgRef] = useState(null);
  const [fileUrl, setfileUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [bloodGrpData,setBloodGrpData] = useState([]);
  const [gotraMaster,setGotraMasterData] = useState([]);
  const [maritalStatusData,setMaritalStatusData] = useState([]);
  const [relationMasterData,setRelationMasterData] = useState([]);
  const [modalRelationMasterData, setModalRelationMasterData] = useState([]);
  const [countryData,setCountryData] = useState([]);
  const [stateData,setStateData] = useState([]);
  const [filterdStateData,setfilterdStateData] = useState([]);
  const [cityData,setCityData] = useState([]);
  const [filterdCityData,setFilterdCityData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [startDate, setstartDate] = useState(new Date());
  const [FHname, setFHName] = useState('');
  const [FHbgrp, setFHBgrp] = useState('1');
  const [FHrelation, setFHrelation] = useState('self');
  const [FHgender, setFHgender] = useState('Male');
  const [FHbirthdate, setFHbirthdate] = useState(new Date());
  const [FHgotra, setFHgotra] = useState('1');
  const [FHMaritalStatus, setFHMaritalStatus] = useState('1');
  const [FHEducation, setFHEducation] = useState('');
  const [FHOccupation, setFHOccupation] = useState('');
  const [FHCompany, setFHCompany] = useState('');
  const [FHNativePlace, setFHNativePlace] = useState('');
  const [FHOldPageNo, setFHOldPageNo] = useState('');
  const [FHOfcadd,setFHOfcadd] = useState('');
  const [FHOfctel,setFHOfctel] = useState('');
  const [FHOfcemail,setFHOfcemail] = useState('');
  const [FHOfcwebsite,setFHOfcwebsite] = useState('');
  const [FHResiadd,setFHResiadd] = useState(null);
  const [FHCountry, setFHCountry] = useState(null);
  const [FHState, setFHState] = useState(null);
  const [FHCity,setFHCity] = useState('101');
  const [FHChecked, setFHChecked] = useState(false);
  const [FMChecked, setFMChecked] = useState(false);
  const [FHProfilePicture, setFHProfilePicture] = useState(null);
  const [FMProfilePicture, setFMProfilePicture] = useState(null);
  const [FHsubmitButton,setFHsubmitButton] = useState('Add Family Member');
  const [FHViewForm,setFHViewForm] = useState(true);

  const [mainCity, setMainCity] = useState('Bombuflat');
  
  const [showFHImageCropModal, setShowFHImageCropModal] = useState(false);
  const [showFMImageCropModal, setShowFMImageCropModal] = useState(false);

  // const [FHZone,setFHZone] = useState(null);
  const [FHRestel,setFHRestel] = useState('');
  const [FHMobile,setFHMobile] = useState('');
  const [FHEmail,setFHEmail] = useState('');
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [show, setShow] = useState(false);
  const [allFamilyDetail, setAllFamilyDetail] = useState([]);
  const [viewAllData, setViewAllData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [modalCheckBoxValue, setmodalCheckBoxValue] = useState(false);
  const [relationJsonData, setRelationJsonData] = useState([]);
  
  const [FMname, setFMName] = useState(null);
  const [FMbgrp, setFMBgrp] = useState('1');
  const [FMrelation, setFMrelation] = useState('2'); 
  const [startModalDate, setstartModalDate] = useState(new Date());
  const [FMbirthdate, setFMbirthdate] = useState(new Date());
  const [FMMaritalStatus, setFMMaritalStatus] = useState('1');
  const [FMEducation, setFMEducation] = useState(null);
  const [FMOccupation, setFMOccupation] = useState(null);
  const [FMCity,setFMCity] = useState('101');
  const [FMGotra,setFMGotra] = useState('');
  const [FMResiadd,setFMResiadd] = useState(null);
  const [FMmob,setFMmob] = useState('');
  const [FMEmail, setFMEmail] = useState(null);
  const [FMCompany, setFMCompany] = useState('');
  const [FMOfcadd,setFMOfcadd] = useState('');
  const [FMOfctel,setFMOfctel] = useState('');
  const [FMOfcemail,setFMOfcemail] = useState('');
  const [FMOfcwebsite,setFMOfcwebsite] = useState('');
  const [FMrelationWith,setFMrelationWith] = useState(null);
  const [FMrelationWithValue,setFMrelationWithValue] = useState(null);
  const [FMsubmitButton,setFMsubmitButton] = useState('Add Member');
  const [EditId, setEditId] = useState(0);

  useEffect(() => {
    var date = new Date();
    var m = date.getMonth()+1;
    var d = date.getDate();
    var y = date.getFullYear();

    if(m.toString().length === 1){
      m = '0'+m.toString();
    }

    if(d.toString().length === 1){
      d = '0'+d.toString();
    }

    var abc = d+'-'+m+'-'+y.toString();
    setFMbirthdate(abc);
    setFHbirthdate(abc);
  }, [])

  const handleModalChange = date => { 
    setstartModalDate(date) 
    var m = date.getMonth()+1;
    var d = date.getDate();
    var y = date.getFullYear();

    if(m.toString().length === 1){
      m = '0'+m.toString();
    }

    if(d.toString().length === 1){
      d = '0'+d.toString();
    }

    var abc = d+'-'+m+'-'+y.toString();
    setFMbirthdate(abc);
  };
  const changeFMname = (value) => { setFMName(value) };
  const changeFMBgrp = (value) => { 
   // console.log(value.value);
    setFMBgrp(value.value) 
  };
  const changeFMrelation = (value) => { 
    // console.log(maleJsonData);
    // console.log(maleJsonData.length);
    // console.log(femaleJsonData);
    // console.log(femaleJsonData.length);
    // console.log(jsonData[0].maritalstatus);
    var ms = jsonData[0].maritalstatus;
    setFMrelation(value.value);
    if(value.value === '2'){
      if(maleJsonData[1] === undefined){
        setRelationJsonData([]);
      }else if(ms === '3' || ms === '4' || ms === '5'){
        setRelationJsonData([]);
      }
      else{
        setRelationJsonData(maleJsonData);
      }  
    }else if(value.value === '10'){
      setRelationJsonData(commonMemberJsonData);
    }else if(value.value === '11'){
      setRelationJsonData(commonMemberJsonData);
    }else if(value.value === '3' || value.value === '5'){
      if(ms === '3' || ms === '4' || ms === '5'){
        setRelationJsonData(commonMemberJsonData);
      }else if(ms === '1'){
        if(parentsJsonData.length > 1){
          setRelationJsonData(parentsJsonData);
        }else{
          setRelationJsonData(commonMemberJsonData);
        }
      }
    }else{

    }
  };

  const changeFMrelationWith = (value) => {
    setFMrelationWithValue(value.value)
    setFMrelationWith(value.value);
  }
  const changeFMMaritalStatus = (value) => { 
   // console.log(value);
    //console.log(value.value);
    setFMMaritalStatus(value.value) 
  };
  const changeFMEducation = (value) => { setFMEducation(value) };
  const changeFMOccupation = (value) => { setFMOccupation(value) };
  const changeFMmob = (value) => { 
   // console.log(value);
    if(value.match("^[0-9]*$")!=null){
      setFMmob(value) 
    }
  };
  const changeFMEmail = (value) =>{ setFMEmail(value) }
  const changeFMCompany = (value) => { setFMCompany(value) };
  const changeFMOfcadd = (value) => { setFMOfcadd(value) };
  const changeFMOfctel = (value) => { 
    if(value.match("^[0-9]*$")!=null){
      setFMOfctel(value) 
    }
  };
  const changeFMOfcemail = (value) => { setFMOfcemail(value) };
  const changeFMOfcwebsite = (value) => { setFMOfcwebsite(value) };

  const handleCheckboxValue = () => { 
    setCheckboxValue(!checkBoxValue) 
    setFHChecked(!FHChecked)
    setFHCompany('');
    setFHOfcadd('');
    setFHOfctel('');
    setFHOfcemail('');
    setFHOfcwebsite('');
  }
  const handleModalCheckboxValue = () => { 
    setmodalCheckBoxValue(!modalCheckBoxValue) 
    setFMChecked(!FMChecked)
    setFMCompany('');
    setFMOfcadd('');
    setFMOfctel('');
    setFMOfcemail('');
    setFMOfcwebsite('');
  }

  const handleChange = date => { 
    setstartDate(date) 
    //console.log(date);
    var m = date.getMonth()+1;
    var d = date.getDate();
    var y = date.getFullYear();

    if(m.toString().length === 1){
      m = '0'+m.toString();
    }

    if(d.toString().length === 1){
      d = '0'+d.toString();
    }

    var abc = d+'-'+m+'-'+y.toString();
    setFHbirthdate(abc);
   // console.log(abc);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function changeFHName(value) { setFHName(value) }
  const changeFHBgrp = (value) =>{ setFHBgrp(value.value) }
  const changeFHgender = (value) =>{ setFHgender(value) }
  const changeFHgotra = (value) =>{ setFHgotra(value.value) }
  const changeFHMaritalStatus = (value)=> { 
   // console.log(value);
   // console.log(value.value);
    setFHMaritalStatus(value.value) 
  }
  const changeFHEducation = (value) =>{ setFHEducation(value) }
  const changeFHOccupation= (value) =>{ setFHOccupation(value) }
  const changeFHCompany = (value) =>{ setFHCompany(value) }
  const changeFHOfcadd = (value) =>{ setFHOfcadd(value) }
  const changeFHOfctel = (value) =>{ 
    if(value.match("^[0-9]*$")!=null){
      setFHOfctel(value)
    }
    // if(value.match("^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$")!=null){
    //   setFHOfctel(value)
    // }
  }
  const changeFHOfcemail = (value) =>{ setFHOfcemail(value) }
  const changeFHOfcwebsite = (value) =>{ setFHOfcwebsite(value) }
  const changeFHResiadd = (value) =>{ setFHResiadd(value) }
  const changeFHCountry = (value) => {
    setFHCountry(value.value) 
    var states = stateData.filter((item) => {
      return item.country_id === (value.value)
    })
    setfilterdStateData(states);
  }
  const changeFHState = (value) => {
    setFHState(value.value) 
    var city = cityData.filter((item) => {
      return item.state_id === (value.value)
    })
    // console.log(city);
    if(city.length === 0){
      setFHCity(value.value);
      const abc = stateData.filter((item)=> item.id === value.value)
    // console.log(abc);
      setMainCity(abc[0].name)
    }else{
      setFHCity(city[0].id);
      setMainCity(city[0].name)
    }
    setFilterdCityData(city);
  }
  const changeFHCity = (value) =>{ 
    // console.log(FHCity);
    // console.log(cityData);
    setFHCity(value.value) 
    const abc = cityData.filter((item)=> item.id === value.value)
    // console.log(abc);
    setMainCity(abc[0].name)
  }
    // const changeFHZone = (value) =>{ setFHZone(value) }
  const changeFHRestel = (value) =>{ 
    if(value.match("^[0-9]*$")!=null){
      setFHRestel(value)
    }
  }
  const changeFHMobile = (value) =>{ 
    if(value.match("^[0-9]*$")!=null){
      setFHMobile(value) 
    }
  }
  const changeFHEmail = (value) =>{ setFHEmail(value) }
  const changeFHNativePlace = (value) => { setFHNativePlace(value) }
  const changeFHOldPageNo = (value) => { setFHOldPageNo(value) }

  function clearForm(){
    // console.log('clear form called');
    setCountryData(countryData);
    setStateData(stateData);
    setCityData(cityData);
    let statedata = stateData.filter((item)=>{
      return item.country_id === '101'
    })
    let citydata = cityData.filter((item)=>{
      return item.state_id === '1'
    })
    //setFHState(null);
    setFHCountry(countryData[0].id);
    setFHCity(citydata[0].id);
    setfilterdStateData(statedata);
    setFilterdCityData(citydata);
    // console.log(cityData);
    setstartDate(new Date());
    setFHName('');
    setFHBgrp('1');
    setFHrelation('Self');
    setFHgender('Male');
    setFHgotra('1');
    setFHMaritalStatus('1');
    setFHEducation('');
    setFHOccupation('');
    setFHCompany('');
    setFHOfcadd('');
    setFHOfctel('');
    setFHOfcemail('');
    setFHOfcwebsite('');
    setFHResiadd('');
    setFHNativePlace('');
    setFHOldPageNo('');
    // setFHCity(cityData[0].id);
    // setFHZone(null);
    setFHRestel('');
    setFHMobile('');
    setFHEmail('');
    setImgurl(require('./../src/icons/file-upload.png'));
    setCroppedimgurl(require('./../src/icons/file-upload.png'));
  }

  function handleSubmit(event) {
    event.preventDefault();
    // console.log(FHRestel);
    if(FHRestel === ''){
      callValidations();
    }else{
      //console.log(reg2.test(FHRestel));
      if(reg2.test(FHRestel) === false) {
        alert('please enter valid Residential telephone number.');
      }else{
        callValidations();
      }
    }
  };

  function callValidations(){
    if(reg1.test(FHMobile) === false) {
      alert('please enter valid mobile number.');
    }else{
      if(emailreg.test(FHEmail) === false){
        alert('please enter valid email address.');
      }else{
        if( checkBoxValue === true ){
          if(reg1.test(FHOfctel) === false) {
            alert('please enter valid office telephone number.');
          }else{
            if(emailreg.test(FHOfcemail) === false){
              alert('please enter valid email address.');
            }else{
              if(FHOfcwebsite === ''){
                createHeadJsonData();
              }else{
                if(regexp.test(FHOfcwebsite) === false){
                  alert('please enter valid website url.');
                }else{
                 // console.log(jsonData.length);
                  createHeadJsonData();
                }
              }
            }
          }
        }else{
         // console.log(jsonData.length);
          createHeadJsonData();
        }
      }
    }
  }

  function createHeadJsonData(){
    if(jsonData.length === 0){
      var memberData = {
        'id': new Date().getTime(),
        'fullname': FHname,
        'bloodgroup': FHbgrp,
        'relation': relationMasterData[0].id,
        'relationwith': relationMasterData[0].id,
        'gender': FHgender,
        'birthdate': FHbirthdate,
        'gotra': FHgotra,
        'education': FHEducation,
        'maritalstatus': FHMaritalStatus,
        'occupation': FHOccupation,
        'companyname': FHCompany,
        'officeaddress': FHOfcadd,
        'officeTelephone': FHOfctel,
        'officeemail': FHOfcemail,
        'website': FHOfcwebsite,
        'resiaddress': FHResiadd,
        'city': FHCity,
        'native_place': FHNativePlace,
        'vastipatrak_number': FHOldPageNo,
        // 'zone': FHZone,
        'resitelephone': FHRestel,
        'mobilenumber': FHMobile,
        'email': FHEmail,
        'profile_picture': FHProfilePicture
      }
      jsonData.push(memberData);
      // console.log(jsonData);
      for(var i=0;i<jsonData.length;i++){
        if(jsonData[i].gender === 'Male'){
        //  console.log('Male');
          var abc= {
            'fullname': jsonData[i].fullname,
            'id': jsonData[i].id
          }
          maleJsonData.push(abc);
        }
      }
      //console.log(maleJsonData);
      //setRelationJsonData(maleJsonData);
      for(var i=0;i<jsonData.length;i++){
        if(jsonData[i].gender === 'Female'){
         // console.log('Female');
          var xyz= {
            'fullname': jsonData[i].fullname,
            'id': jsonData[i].id
          }
          femaleJsonData.push(xyz);
        }
      }

      for(var i=0;i<jsonData.length;i++){
        var xyz= {
          'fullname': jsonData[i].fullname,
          'id': jsonData[i].id
        }
        commonMemberJsonData.push(xyz);
      }
      // console.log(commonMemberJsonData);
      // console.log(femaleJsonData);
      // clearForm();
      localStorage.setItem('allmemberdata', JSON.stringify(jsonData));
      var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
      setViewAllData(storedData);
      setRelationJsonData(maleJsonData);
      setShowAddButton(true);
      setCheckboxValue(false) 
      setFHChecked(false)
      clearForm();
      // var officersIds = officers.map(function (officer) {
      //   return officer.id
      // });
      setShow(true);
      setFHViewForm(false);
      setShowViewData(true);
      
    }else{
      // console.log(jsonData);
      jsonData[0].id = jsonData[0].id;
      jsonData[0].fullname = FHname;
      jsonData[0].bloodgroup = FHbgrp;
      jsonData[0].relation = relationMasterData[0].id;
      jsonData[0].relationwith = relationMasterData[0].id
      jsonData[0].gender = FHgender;
      jsonData[0].birthdate = FHbirthdate;
      jsonData[0].gotra = FHgotra;
      jsonData[0].education = FHEducation;
      jsonData[0].maritalstatus = FHMaritalStatus;
      jsonData[0].occupation = FHOccupation;
      jsonData[0].companyname = FHCompany;
      jsonData[0].officeaddress = FHOfcadd;
      jsonData[0].officeTelephone = FHOfctel;
      jsonData[0].officeemail = FHOfcemail;
      jsonData[0].website = FHOfcwebsite;
      jsonData[0].resiaddress = FHResiadd;
      jsonData[0].city = FHCity;
      jsonData[0].native_place = FHNativePlace;
      jsonData[0].vastipatrak_number = FHOldPageNo;
      // jsonData[0].zone = FHZone;
      jsonData[0].resitelephone = FHRestel;
      jsonData[0].mobilenumber = FHMobile;
      jsonData[0].email = FHEmail;
      jsonData[0].profile_picture = FHProfilePicture;

      for(var i=0;i<jsonData.length;i++){
        jsonData[i].gotra = FHgotra;
      }
    // console.log(jsonData)
      localStorage.setItem('allmemberdata', JSON.stringify(jsonData));
      var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
      if(FHMaritalStatus === '2' || FHMaritalStatus === '3' || FHMaritalStatus === '4' || FHMaritalStatus === '5'){
        setRelationJsonData([]);
      }
      setFHgotra(FHgotra);
      setViewAllData(storedData);
      setShowAddButton(true);
      setFHViewForm(false);
      setShowViewData(true);
      setCheckboxValue(false) 
      setFHChecked(false)
      setFHsubmitButton('Add Family Member')
      clearForm();
      viewData();
    }
  }

  const handleModalSubmit = event => {
    event.preventDefault();
    // console.log(FMmob);
    if(FMmob === ''){
    // console.log(jsonData[0].mobilenumber);
      setFMmob(jsonData[0].mobilenumber);
      callModalFormSubmit(jsonData[0].mobilenumber);
    }else{
      if(reg1.test(FMmob) === false) {
        alert('please enter valid mobile number.');
      }else{
        callModalFormSubmit(FMmob);
      }
    }
  };

  function callModalFormSubmit(mobile){
   // console.log(FHgotra);
    if( EditId === 0 ){
      var id = new Date().getTime();
      var gender;
      if(FMrelation === '3' || FMrelation === '11'){
        gender = 'Male';
      }else{
        gender = 'Female';
      }
      var memberData = {
        'birthdate': FMbirthdate,
        'bloodgroup': FMbgrp,
        'city': jsonData[0].city,
        'companyname': FMCompany,
        'education': FMEducation,
        'email': FMEmail,
        'fullname': FMname,
        'gender': gender,
        'gotra': jsonData[0].gotra,
        'id': id,
        'maritalstatus': FMMaritalStatus,
        'mobilenumber': mobile,
        'occupation': FMOccupation,
        'native_place': jsonData[0].native_place,
        'vastipatrak_number': jsonData[0].vastipatrak_number,
        'officeTelephone': FMOfctel,
        'officeaddress': FMOfcadd,
        'officeemail': FMOfcemail,
        'relation': FMrelation,
        'relationwith': FMrelationWith,
        'resiaddress': jsonData[0].resiaddress,
        'resitelephone': null,
        'website': FMOfcwebsite,
        'profile_picture': FMProfilePicture
      }
      jsonData.push(memberData);
      // console.log(jsonData);
      // setAllFamilyDetail(jsonData);
      localStorage.setItem('allmemberdata', JSON.stringify(jsonData));
      var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
      setViewAllData(storedData);
      setShowAddButton(true);

      // console.log(maleJsonData);
      // console.log(femaleJsonData);

      var uyu = {
        'fullname': FMname,
        'id': id
      }
      commonMemberJsonData.push(uyu);
     // console.log(commonMemberJsonData);

      if(FMrelation === '3' || FMrelation === '11'){
        var abc= {
          'fullname': FMname,
          'id': id
        }
        maleJsonData.push(abc);
      //  console.log(maleJsonData);
        //setRelationJsonData(maleJsonData);
      }else if(FMrelation === '2' || FMrelation === '5' || FMrelation === '11'){
        var abc= {
          'fullname': FMname,
          'id': id
        }
        femaleJsonData.push(abc);
       // console.log(femaleJsonData);
        //setRelationJsonData(femaleJsonData);
      }else{

      }
      clearForm();

      for(var i=0;i<storedData.length;i++){
        for(var j=0;j<storedData.length;j++){
          if(storedData[i].id === parseInt(storedData[j].relationwith)){
            if(storedData[j].relation === '2'){
              var qwe = {
                'id': storedData[i].id,
                'fullname': storedData[i].fullname + ' and ' +storedData[j].fullname
              }
              parentsJsonData.push(qwe);
            }
            else{
              var qwe = {
                'id': storedData[j].id,
                'fullname': storedData[j].fullname
              }
              parentsJsonData.push(qwe);
            }
          }
        }
      }
      // console.log(parentsJsonData);
      var obj = {};
      for(var i=0, len=parentsJsonData.length; i < len; i++)
        obj[parentsJsonData[i]['fullname']] = parentsJsonData[i];
        parentsJsonData = new Array();
      for(var key in obj)
      parentsJsonData.push(obj[key]);

      var obj1 = {};
      for(var i=0, len=parentsJsonData.length; i < len; i++)
        obj1[parentsJsonData[i]['id']] = parentsJsonData[i];
        parentsJsonData = new Array();
      for(var key in obj1)
      parentsJsonData.push(obj1[key]);
      // console.log(parentsJsonData);
     
      setShow(false);
      setEditId(0);
      setFMName(null);
      setFMBgrp('1');
      setFMrelation('2');
      setFMMaritalStatus('1');
      setFMEducation(null);
      setFMOccupation(null);
      setFMmob('');
      setFMEmail(null);
      setFMCompany(null);
      setFMOfcadd(null);
      setFMOfctel(null);
      setFMOfcemail(null);
      setFMOfcwebsite(null);
      setMemberImgurl(require('./../src/icons/file-upload.png'));
      setMemberCroppedimgurl(require('./../src/icons/file-upload.png'));
      viewData();
    }else{
      for(var i=0; i<jsonData.length;i++){
        if( EditId === jsonData[i].id ){
          var gender;
          if(jsonData[i].relation === '3' || jsonData[i].relation === '11'){
            gender = 'Male';
          }else{
            gender = 'Female';
          }
         // console.log('if');
          jsonData[i].fullname = FMname;
          jsonData[i].bloodgroup = FMbgrp;
          jsonData[i].relation = FMrelation;
          jsonData[i].relationwith = FMrelationWith;
          jsonData[i].education = FMEducation;
          jsonData[i].birthdate = FMbirthdate;
          jsonData[i].maritalstatus = FMMaritalStatus;
          jsonData[i].occupation = FMOccupation;
          jsonData[i].mobilenumber = FMmob;
          jsonData[i].email = FMEmail;
          jsonData[i].gender = gender;
          jsonData[i].companyname = FMCompany;
          jsonData[i].officeaddress = FMOfcadd;
          jsonData[i].officeTelephone = FMOfctel;
          jsonData[i].officeemail = FMOfcemail;
          jsonData[i].website = FMOfcwebsite;
          jsonData[i].profile_picture = FMProfilePicture

          for(var i=0;i<maleJsonData.length;i++){
            if(jsonData[i].id === maleJsonData[i].id){
              maleJsonData[i].fullname = FMname
            }
          }
          //console.log(maleJsonData);
    
          for(var i=0;i<femaleJsonData.length;i++){
            if(jsonData[i].id === femaleJsonData[i].id){
              femaleJsonData[i].fullname = FMname
            }
          }
          //console.log(femaleJsonData);
    
          // for(var i=0;i<commonMemberJsonData.length;i++){
          //   if(jsonData[i].id === commonMemberJsonData[i].id){
          //     commonMemberJsonData[i].fullname = FMname
          //   }
          // }
          //console.log(commonMemberJsonData);

        //  console.log(jsonData)
          localStorage.setItem('allmemberdata', JSON.stringify(jsonData));
          var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
          setViewAllData(storedData);
          setShow(false);
          setEditId(0);
          setFMName(null);
          setFMBgrp('1');
          setFMrelation('2');
          setFMMaritalStatus('1');
          setFMEducation(null);
          setFMOccupation(null);
          setFMmob('');
          setFMEmail(null);
          setMemberImgurl(require('./../src/icons/file-upload.png'));
          setMemberCroppedimgurl(require('./../src/icons/file-upload.png'));
          setFMsubmitButton('Add Member');
          viewData();
        }else{
         
        }
      }
    }
  }

  function viewData(){
  //  console.log('view data code');
    var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
  //  console.log(storedData);
    if(storedData === null){
      return(
        <div></div>
      )
    }else{
      if(storedData.length >= 1){
        //console.log(storedData[0].fullname);
        //console.log(storedData[0].city);
        return(
          <div className="view-data">
            <Row>
              <Col className="header">Member Details</Col>
            </Row>
            <Row>
              <Col md={12} className="scroll">
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>Profile Picture</th>  
                    <th>Full Name</th>
                    <th>Bld-Group</th>
                    <th>Relation</th>
                    <th>RelationWith</th>
                    <th>Gender</th>
                    <th>Birthdate</th>
                    <th>Gotra</th>
                    <th>Education</th>
                    <th>Marital Status</th>
                    <th>Occupation</th>
                    <th>Company Name</th>
                    <th>Office Add</th>
                    <th>Office Tel</th>
                    <th>Office email</th>
                    <th>website</th>
                    <th>Res.Add</th>
                    <th>City</th>
                    <th>Native Place</th>
                    <th>Old vastipatrak page no</th>
                    {/* <th>Zone</th> */}
                    <th>Res.Tel</th>
                    <th>Mo.Number</th>
                    <th>email</th>
                    <th>actions</th>
                  </tr>
                </thead>
                <tbody>
                {viewAllData.map((item) => 
                  <tr>
                    {viewImage(item.profile_picture)}
                    <td>{item.fullname}</td>
                    {viewBldGrp(item.bloodgroup)}
                    {viewRelation(item.relation)}
                    {viewRelationWith(item.relationwith)}
                    <td>{item.gender}</td>
                    <td>{item.birthdate}</td>
                    {viewGotraDetails(item.gotra)}
                    <td>{item.education}</td>
                    {viewMaritalStatus(item.maritalstatus)}
                    <td>{item.occupation}</td>
                    <td>{item.companyname}</td>
                    <td>{item.officeaddress}</td>
                    <td>{item.officeTelephone}</td>
                    <td>{item.officeemail}</td>
                    <td>{item.website}</td>
                    <td>{item.resiaddress}</td>
                    {viewCity(item.city)}
                    <td>{item.native_place}</td>
                    <td>{item.vastipatrak_number}</td>
                    {/* <td>{item.zone}</td> */}
                    <td>{item.resitelephone}</td>
                    <td>{item.mobilenumber}</td>
                    <td>{item.email}</td>
                    {viewActions(item.id, item.relationwith ,item)}
                  </tr>
                )}
                </tbody>
              </Table>
              </Col>
            </Row>
          </div>
        )
      }else{
        return(
          <div></div>
        )
      }
    }
  }

  function viewActions(id,relationWith,item){
    if(relationWith === relationMasterData[0].id){
      return(
        <td>
          <div style={{ display: 'flex' }}>
            <Button variant="success" style={{ marginRight: 5, marginLeft: 5 }} onClick={()=>editData(id,relationWith,item)}><img src={require('./icons/edit.png')} style={{width: 22 , height: 22}}/></Button>
          </div>
        </td>
      )
    }else{
      return(
        <td>
          <div style={{ display: 'flex' }}>
            <Button variant="success" style={{ marginRight: 5, marginLeft: 5 }} onClick={()=>editData(id,relationWith,item)}><img src={require('./icons/edit.png')} style={{width: 22 , height: 22}}/></Button>
            <Button variant="danger" style={{ marginRight: 5, marginLeft: 5 }} onClick={()=>deleteData(id)}><img src={require('./icons/delete.png')} style={{width: 22 , height: 22}}/></Button>
          </div>
        </td>
      )
    }
  }

  function viewImage(img){
    if(img === null){
      return(
        <td>{<img src={require('./../src/icons/file-upload.png')} width="100" height="100"/>}</td>
      )
    }else{
      return(
        <td>{<img src={img} width="100" height="100"/>}</td>
      )
    }
  }

  function viewBldGrp (bg){
    for(var i=0;i<bloodGrpData.length;i++){
      if(bg === bloodGrpData[i].id){
        return(
          <td>{bloodGrpData[i].blood_group}</td>
        )
      }
    }
  }

  function viewGotraDetails(gotra){
    for(var i=0;i<gotraMaster.length;i++){
      if(gotra === gotraMaster[i].id){
        return(
          <td>{gotraMaster[i].gotra}</td>
        )
      }
    }
  }

  function viewRelation(bg){
    for(var i=0;i<relationMasterData.length;i++){
      if(bg === relationMasterData[i].id){
        return(
          <td>{relationMasterData[i].name}</td>
        )
      }
    }
  }

  function viewRelationWith (bg){
   // console.log('realtion', bg);
   // console.log('realtion', typeof bg);
   // console.log(maleJsonData);
    var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
   // console.log(storedData);
    for(var i=0;i<storedData.length;i++){
      if(storedData[i].id === parseInt(bg)){ return( <td>{storedData[i].fullname}</td> )}
      else { return(<td>---</td>) }
    }
  }

  function viewMaritalStatus(bg){
   // console.log(bg);
    for(var i=0;i<maritalStatusData.length;i++){
      if(bg === maritalStatusData[i].id){ return( <td>{maritalStatusData[i].name}</td> )}
    }
  }

  function viewCity(bg){
    //console.log(FHState);
    //console.log(stateData);
    let data = cityData.filter((item) => {
      return item.state_id === FHState
    })
    //console.log(data);
    if(data.length === 0){
      for(var i=0;i<stateData.length;i++){
        if(stateData[i].id === bg){
          return(<td>{stateData[i].name}</td>)
        } else if(bg === 'null') {
          return(<td>null</td>)
        }
      }
    }else{
      for(var i=0;i<data.length;i++){
        if(data[i].id === bg){
          return(<td>{data[i].name}</td>)
        } else if(bg === 'null') {
          return(<td>null</td>)
        }
      }
    }
  }

  function deleteData(id) {
    //console.log(id);
    var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
    //console.log(storedData);
    for(var i=0;i<jsonData.length;i++){
      if(jsonData[i].id === id){ jsonData.splice(i,1); }
    }
   // console.log(jsonData);
    if(jsonData.length === 0){
      setShowAddButton(false);
    }
   
    for(var i=0;i<maleJsonData.length;i++){
      if(maleJsonData[i].id === id){ maleJsonData.splice(i,1); }
    }
    // console.log(maleJsonData);
    for(var i=0;i<femaleJsonData.length;i++){
      if(femaleJsonData[i].id === id){ femaleJsonData.splice(i,1); }
    }
    // console.log(femaleJsonData);
    for(var i=0;i<commonMemberJsonData.length;i++){
      if(commonMemberJsonData[i].id === id){ commonMemberJsonData.splice(i,1); }
    }
    // console.log(commonMemberJsonData);
    for(var i=1;i<parentsJsonData.length;i++){
      parentsJsonData.splice(i,1);
    }
    // console.log(parentsJsonData);
    for(var i=0;i<storedData.length;i++){
      if(storedData[i].id === id){
        storedData.splice(i,1);
      }
    }
    localStorage.setItem('allmemberdata', JSON.stringify(storedData));
    var data = JSON.parse(localStorage.getItem('allmemberdata'));
    setViewAllData(data);
    // console.log(data);
    for(var i=0;i<data.length;i++){
      for(var j=0;j<data.length;j++){
        if(data[i].id === parseInt(data[j].relationwith)){
          if(data[j].relation === '2'){
            var qwe = {
              'id': data[i].id,
              'fullname': data[i].fullname + ' and ' +data[j].fullname
            }
            parentsJsonData.push(qwe);
          }
          else{
            var qwe = {
              'id': data[j].id,
              'fullname': data[j].fullname
            }
            parentsJsonData.push(qwe);
          }
        }
      }
    }
    // console.log(parentsJsonData);
    var obj = {};
    for(var i=0, len=parentsJsonData.length; i < len; i++)
      obj[parentsJsonData[i]['fullname']] = parentsJsonData[i];
      parentsJsonData = new Array();
    for(var key in obj)
    parentsJsonData.push(obj[key]);

    var obj1 = {};
    for(var i=0, len=parentsJsonData.length; i < len; i++)
      obj1[parentsJsonData[i]['id']] = parentsJsonData[i];
      parentsJsonData = new Array();
    for(var key in obj1)
    parentsJsonData.push(obj1[key]);
    // console.log(parentsJsonData);
  }

  function fileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        //console.log(reader.result)
        setImgurl(reader.result),
        //setCroppedimgurl(reader.result),
        setViewCropTool(true),
        setShowFHImageCropModal(true)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function fileModalChange(event){
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        //console.log(reader.result)
        setMemberImgurl(reader.result),
        //setMemberCroppedimgurl(reader.result),
        setMemberViewCropTool(true),
        setShowFMImageCropModal(true)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function editData(id, relationWith ,item) {
    //console.log(item);
    if(relationWith === relationMasterData[0].id){
      setShowViewData(false);
      setFHViewForm(true);
      setFHName(item.fullname) 
      setFHBgrp(item.bloodgroup) 
      setFHgender(item.gender)
      setFHgotra(item.gotra) 
      var dateString = item.birthdate;
      var dateParts = dateString.split("-");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      setFHbirthdate(item.birthdate)
      setstartDate(dateObject)
      setFHMaritalStatus(item.maritalstatus)
      setFHEducation(item.education)
      setFHOccupation(item.occupation)
      setFHNativePlace(item.native_place)
      setFHOldPageNo(item.vastipatrak_number)
      setFHCompany(item.companyname)
      setFHOfcadd(item.officeaddress)
      setFHOfctel(item.officeTelephone)
      setFHOfcemail(item.officeemail)
      setFHOfcwebsite(item.website)
      setFHResiadd(item.resiaddress) 
      setFHCity(item.city)
      // setFHZone(item.zone)
      setFHRestel(item.resitelephone)
      setFHMobile(item.mobilenumber)
      setFHEmail(item.email)
      setFHsubmitButton('Update Family Member')
      if(item.profile_picture === null){
        setCroppedimgurl(require('./../src/icons/file-upload.png'));
      }else{
        setCroppedimgurl(item.profile_picture);
      }
     
      if(item.companyname === ''){
        setCheckboxValue(false) 
        setFHChecked(false)
      }else{
        setCheckboxValue(true) 
        setFHChecked(true)
      }
      setShowAddButton(false)
    }else{
    // console.log(item);
      setEditId(id);
      setFMName(item.fullname) 
      setFMBgrp(item.bloodgroup) 
      setFMsubmitButton('Update Member')
      setFMbirthdate(item.birthdate);
    // console.log(item.birthdate);
      var dateString = item.birthdate;
      var dateParts = dateString.split("-");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
    // console.log(dateObject);
      setstartModalDate(dateObject)
      setFMrelation(item.relation);
      setFMCompany(item.companyname)
      setFMOfcadd(item.officeaddress)
      setFMOfctel(item.officeTelephone)
      setFMOfcemail(item.officeemail)
      setFMOfcwebsite(item.website)
      if(item.companyname === '' || item.companyname === null){
        setmodalCheckBoxValue(false) 
        setFMChecked(false)
      }else{
        setmodalCheckBoxValue(true) 
        setFMChecked(true)
      }

      // console.log(jsonData[0].maritalstatus);
      var ms = jsonData[0].maritalstatus;
      if(item.relation === '2'){
        if(maleJsonData[1] === undefined){
          setRelationJsonData([]);
        }else{
          setRelationJsonData(maleJsonData);
        }  
      }else if(item.relation === '10'){
        setRelationJsonData(commonMemberJsonData);
      }else if(item.relation === '11'){
        setRelationJsonData(commonMemberJsonData);
      }else if(item.relation === '3' || item.relation === '5'){
        if(ms === '3' || ms === '4' || ms === '5'){
          if(maleJsonData[1] === undefined){
            setRelationJsonData([]);
          }
        }else if(ms === '1'){
          if(parentsJsonData.length > 1){
            setRelationJsonData(parentsJsonData);
          }else{
            setRelationJsonData(commonMemberJsonData);
          }
        }
      }else{
  
      }

      setFMMaritalStatus(item.maritalstatus)
      setFMEducation(item.education)
      setFMOccupation(item.occupation)
      setFMmob(item.mobilenumber)
      setFMEmail(item.email);
      if(item.profile_picture === null){
        setMemberCroppedimgurl(require('./../src/icons/file-upload.png'));
      }else{
        setMemberCroppedimgurl(item.profile_picture);
      }
      setShow(true);
    }
    // var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
    // console.log(storedData);
    // localStorage.setItem('allmemberdata', JSON.stringify(storedData));

  }

  const closeModal = () => {
    setShow(false);
    setEditId(0);
    setFMName(null);
    setFMBgrp('1');
    setFMrelation('Wife');
    setstartModalDate(new Date())
    setFMMaritalStatus('1');
    setFMEducation(null);
    setFMOccupation(null);
    setFMmob('');
    setFMEmail('');
    setmodalCheckBoxValue(false) 
    setFMChecked(false)
    setFMCompany('');
    setFMOfcadd('');
    setFMOfctel('');
    setFMOfcemail('');
    setFMOfcwebsite('');
    setMemberCroppedimgurl((require('./../src/icons/file-upload.png')));
    setFMsubmitButton('Add Member')
  }

  const submitForm = async() => {
    setShowLoadingModal(true);
    var storedData = JSON.parse(localStorage.getItem('allmemberdata'));
    // console.log(storedData);
    // console.log(JSON.stringify(storedData));
    let coord = await fetch('http://shrimalibrahma.thingsofinternet.co.in/mobileapp/index.php/AdminApp/registerMember', {
      method: 'POST',
      body: JSON.stringify(storedData)
    })
    .then((response) => {
      return response.json()
    })
    .then((responseJson) => {
    // console.log(responseJson);
      setShowLoadingModal(false);
      if(responseJson.success === 1){
        setShowMessageModal(true)
        setResponseMessage(responseJson.message)
        setShowAddButton(false)
        setFHViewForm(true)
        var abc= localStorage.removeItem('allmemberdata');
    // console.log(abc);
        jsonData = [];
    // console.log(jsonData);
      }
    })
    .catch(error => console.log(error));
  }

  function onImageLoaded (image){
    // console.log('onCropComplete', image)
    setImgRef(image);
    // this.imageRef = image;
  }

  function onCropComplete (crop) {
    // console.log('onCropComplete', crop)
    makeClientCrop(crop);
  }

  function onCropChange (crop) {
    // console.log(crop);
    setCrops(crop);
    // console.log(crop)
  }

  async function makeClientCrop (crop) {
    // console.log(crop);
    if (imgRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedFHImage(croppedImageUrl);
    // console.log(croppedImageUrl);
    // console.log(typeof croppedImageUrl);
    // setCroppedimgurl(croppedImageUrl);
    }
  }

  function onMemberImageLoaded (image){
    // console.log('onCropComplete', image)
    setMemberImgRef(image);
    //this.imageRef = image;
  }

  function onMemberCropComplete (crop) {
    // console.log('onCropComplete', crop)
    makeMemberClientCrop(crop);
  }

  function onMemberCropChange (crop) {
    setMemberCrops(crop);
    //console.log(crop)
  }

  async function makeMemberClientCrop (crop) {
    // console.log(crop);
    if (memberimgRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        memberimgRef,
        crop,
        'newFile.jpeg'
      );
    // console.log(croppedImageUrl);
    // setMemberCroppedimgurl(croppedImageUrl);
      setCroppedFMImage(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL('image/png',1).replace('data:image/png;base64,','');
  }

  function showImagePreview(){
   // console.log(croppedimgurl);
    return(
      <label htmlFor="file-upload" className="custom-file-upload" >
        <img src={croppedimgurl} style={{ width: 200, height: 200 }}/>
      </label>
    )
  }

  function showModalImagePreview(){
    return(
      <label htmlFor="file-member-upload" className="custom-file-upload" >
        <img src={membercroppedimgurl} style={{ width: 200, height: 200 }}/>
      </label>
    )
  }

  async function uploadImage(){
    setShowLoadingModal(true);
    const data = {
      profile_picture : croppedFHImage
    }
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // console.log(data);
    let coord = await fetch('http://shrimalibrahma.thingsofinternet.co.in/mobileapp/index.php/AdminApp/uploadMemberProfilePicture', {
      method: 'POST',
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      body:formBody
    })
    .then((response) => { 
    // console.log(response);
      return response.json()
    })
    .then((responseJson) => {
      setShowLoadingModal(false);
    // console.log(responseJson);
      setCroppedimgurl(responseJson.profilePicture);
      setShowFHImageCropModal(false);
      setFHProfilePicture(responseJson.profilePicture);
      setViewCropTool(false);
    })
    .catch((error) => {
      setShowLoadingModal(false);
      setShowFHImageCropModal(false);
      alert(error);
    })
  }

  async function uploadMemberImage(){
    setShowLoadingModal(true);
    const data = {
      profile_picture : croppedFMImage
    }
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // console.log(data);
    let coord = await fetch('http://shrimalibrahma.thingsofinternet.co.in/mobileapp/index.php/AdminApp/uploadMemberProfilePicture', {
      method: 'POST',
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      body:formBody
    })
    .then((response) => { 
    // console.log(response);
      return response.json()
    })
    .then((responseJson) => {
      setShowLoadingModal(false);
    // console.log(responseJson);
      setShowFMImageCropModal(false);
      setMemberCroppedimgurl(responseJson.profilePicture);
      setFMProfilePicture(responseJson.profilePicture);
      setMemberViewCropTool(false);
    })
    .catch((error) => {
      setShowLoadingModal(false);
    // console.log(error);
    })
  }

  return (
  <div>
    <Navbar bg="light" expand="lg" className="justify-content-center menu" fixed="top">
      <Navbar.Brand>Shreemali Brahmin's Registration <br/> e-form - Worldwide</Navbar.Brand>
    </Navbar>
    <Container fluid={true}>
      <Modal
        show={showLoadingModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='loading-modal'
      >
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={100}
          color={'#123abc'}
          loading={loading}
        />
      </Modal>
      <Modal
        show={showMessageModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='msg-modal'
      >
        <Modal.Header>
          <p style={{marginBottom: 0}}>{responseMessage}</p>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={()=> setShowMessageModal(false)}>Okay</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFHImageCropModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='msg-modal'
      >
        <Modal.Header>
          <p style={{marginBottom: 0}}>Crop Your Picture Here</p>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent:'center' }}>
            <div style={{ width: 300, height: 300 }}>
              <ReactCrop
                src={imgurl}
                crop={crops}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=> setShowFHImageCropModal(false)}>Cancel</Button>
          <Button onClick={uploadImage}>Crop image</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFMImageCropModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='msg-modal'
      >
        <Modal.Header>
          <p style={{marginBottom: 0}}>Crop Your Picture Here</p>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent:'center' }}>
            <div style={{ width: 300, height: 300 }}>
              <ReactCrop
                src={memberimgurl}
                crop={membercrops}
                ruleOfThirds
                onImageLoaded={onMemberImageLoaded}
                onComplete={onMemberCropComplete}
                onChange={onMemberCropChange}
              />
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=> setShowFMImageCropModal(false)}>Cancel</Button>
          <Button onClick={uploadMemberImage}>Crop image</Button>
        </Modal.Footer>
      </Modal>
      { FHViewForm && 
        <Row>
          <Col className="header">Family Head Form</Col>
        </Row>
      }
      <Row>
        <Col>
        { FHViewForm &&
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col xs={12} md={3}>
              <div style={{ display:'flex', justifyContent:'center', width:300, height:300, marginTop: 50 }}>
                <div className="center-align">
                  {showImagePreview()}
                  <input id="file-upload" style={{ display: 'none' }} type="file" onChange={fileChange} ref={fileUpload}/>
                </div>
              </div>
            </Col>
          <Col xs={12} md={9}>
          <Form.Row>
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label className="formLabel">Full Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Full name"
              defaultValue={FHname}
              value={FHname}
              onChange={(event)=>changeFHName(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Blood Group</Form.Label>
            <Form.Control as="select" onChange={(event)=>changeFHBgrp(event.target)} defaultValue={FHbgrp} value={FHbgrp}>
              {bloodGrpData.map((item,i) => 
                <option value={item.id} key={i}>{item.blood_group}</option>
              )}
              {/* <option value='hello'>hello</option>
              <option value='hello1'>hello1</option> */}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="1" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Relation</Form.Label>
            <Form.Control as="select" disabled>
              {relationMasterData.map((item,i) => 
                <option value={item.id} key={i}>{item.name}</option>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Gender</Form.Label>
            <Form.Control as="select" onChange={(event)=>changeFHgender(event.target.value)} defaultValue={FHgender} value={FHgender}>
              <option>Male</option>
              <option>Female</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel" style={{ display:'block' }}>Birthdate</Form.Label>
            <DatePicker
              onChange={handleChange}
              value={startDate}
              //locale="en-GB"
              format={'d-MM-yy'}
            />
          </Form.Group>
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Gotra</Form.Label>
            {/* <Form.Control
              required
              type="text"
              placeholder="Gotra"
              defaultValue={FHgotra}
              value={FHgotra}
              onChange={(event)=>changeFHgotra(event.target.value)}
            /> */}
            <Form.Control as="select" onChange={(event)=>changeFHgotra(event.target)} defaultValue={FHgotra} value={FHgotra}>
              {gotraMaster.map((item,i) => 
                <option value={item.id} key={i}>{item.gotra}</option>
              )}
              {/* <option value='hello'>hello</option>
              <option value='hello1'>hello1</option> */}
            </Form.Control>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Education</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Education"
              defaultValue={FHEducation}
              value={FHEducation}
              onChange={(event)=>changeFHEducation(event.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Marital Status</Form.Label>
            <Form.Control as="select" onChange={(event)=>changeFHMaritalStatus(event.target)} defaultValue={FHMaritalStatus} value={FHMaritalStatus}>
              {maritalStatusData.map((item,i) => 
                <option value={item.id} key={i}>{item.name}</option>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label className="formLabel">Occupation</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Occupation"
              defaultValue={FHOccupation}
              value={FHOccupation}
              onChange={(event)=>changeFHOccupation(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label className="formLabel">Resi. Address</Form.Label>
              <Form.Control
                //as='textarea'
                required
                type="text"
                placeholder="Resi. Address"
                defaultValue={FHResiadd}
                value={FHResiadd}
                onChange={(event)=>changeFHResiadd(event.target.value)}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
              <Form.Label className="formLabel">Country</Form.Label>
              <Form.Control as="select" defaultValue={FHCountry} onChange={(event)=>changeFHCountry(event.target)} value={FHCountry}>
                {countryData.map((item,i) => 
                  <option value={item.id} key={i}>{item.name}</option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
              <Form.Label className="formLabel">State</Form.Label>
              <Form.Control as="select" defaultValue={FHState} onChange={(event)=>changeFHState(event.target)} value={FHState}>
                {filterdStateData.map((item,i) => 
                  <option value={item.id} key={i}>{item.name}</option>
                )}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
              <Form.Label className="formLabel">City</Form.Label>
              <Form.Control as="select" defaultValue={FHCity} onChange={(event)=>changeFHCity(event.target)} value={FHCity}>
                {filterdCityData.map((item,i) => 
                  <option value={item.id} key={i}>{item.name}</option>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label className="formLabel">Native Place</Form.Label>
              <Form.Control
                //as='textarea'
                required
                type="text"
                placeholder="Native Place"
                defaultValue={FHNativePlace}
                value={FHNativePlace}
                onChange={(event)=>changeFHNativePlace(event.target.value)}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
              <Form.Label className="formLabel">Old Vastipatrak Page No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Old Vastipatrak Page No"
                defaultValue={FHOldPageNo}
                value={FHOldPageNo}
                onChange={(event)=>changeFHOldPageNo(event.target.value)}
              />
            </Form.Group>
          </Form.Row>
          </Col>
        </Form.Row>

        <Form.Row>
          {/* <Form.Group as={Col} md="1" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Zone</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Zone"
              defaultValue={FHZone}
              onChange={(event)=>changeFHZone(event.target.value)}
            />
          </Form.Group> */}
          <Form.Group as={Col} md="2" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Res. Tel</Form.Label>
            <Form.Control
              type="text"
              placeholder="Res.Tel"
              defaultValue={FHRestel}
              value={FHRestel}
              onChange={(event)=>changeFHRestel(event.target.value)}
              maxLength={15}
            />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Mobile Number (without country code)</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Mobile Number"
              //defaultValue={FHMobile}
              value={FHMobile}
              onChange={(event)=>changeFHMobile(event.target.value)}
              maxLength={10}
              //minLength={10}
            />
          </Form.Group>
         
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label className="formLabel">Email</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Email"
              defaultValue={FHEmail}
              value={FHEmail}
              onChange={(event)=>changeFHEmail(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        { checkBoxValue && 
          <Form.Row>
            <Col style={{ fontWeight: 'bold' }}>Add company details</Col>
          </Form.Row>
        }
        { checkBoxValue &&  
          <Form.Row>
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label className="formLabel">Company name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Company name"
              defaultValue={FHCompany}
              value={FHCompany}
              onChange={(event)=>changeFHCompany(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group> 
          
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label className="formLabel">Office Address</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Office Address"
              defaultValue={FHOfcadd}
              value={FHOfcadd}
              onChange={(event)=>changeFHOfcadd(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Office Tel</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Office Telephone"
              defaultValue={FHOfctel}
              maxLength={15}
              value={FHOfctel}
              onChange={(event)=>changeFHOfctel(event.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
            <Form.Label className="formLabel">Office Email</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Office Email"
              defaultValue={FHOfcemail}
              value={FHOfcemail}
              onChange={(event)=>changeFHOfcemail(event.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label className="formLabel">Website</Form.Label>
            <Form.Control
              type="text"
              placeholder="Website"
              defaultValue={FHOfcwebsite}
              value={FHOfcwebsite}
              onChange={(event)=>changeFHOfcwebsite(event.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          </Form.Row>
        }

        <Form.Row>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" name="checkbox" checked={FHChecked} defaultChecked={FHChecked} label="I have my own business" onChange={handleCheckboxValue}/>
          </Form.Group>
        </Form.Row>
       
        <Form.Row className="justify-content-center">
          <Button type="submit">{FHsubmitButton}</Button>
        </Form.Row>
        </Form> }
        </Col>
      </Row>
      {/* <MyVerticallyCenteredModal
        show={show}
        onHide={() => setShow(false)}
      /> */}
       <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 style={{ fontSize: 16, fontWeight: 700, marginBottom: 0 }}>Family Member Form</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid={true}>
          <Row>
            <Col>
            <Form onSubmit={handleModalSubmit}>
            <Form.Row>
              <Col xs={12} md={12}>
                <div style={{ display:'flex', justifyContent:'center' }}>
                  <div className="center-align" style={{ width:200, height:200 }}>
                    {showModalImagePreview()}
                    <input id="file-member-upload" style={{ display: 'none' }} type="file" onChange={fileModalChange}/>
                  </div>
                </div>
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label className="formLabel">Full Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Full name"
                  defaultValue={FMname}
                  onChange={(event)=>changeFMname(event.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Blood Group</Form.Label>
                <Form.Control as="select" onChange={(event)=>changeFMBgrp(event.target)} value={FMbgrp} defaultValue={FMbgrp}>
                {bloodGrpData.map((item,i) => 
                  <option value={item.id} key={i}>{item.blood_group}</option>
                )}
                </Form.Control>
              </Form.Group>
             
              <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel modalDate">Birthdate</Form.Label>
                <DatePicker
                  onChange={handleModalChange}
                  value={startModalDate}
                  locale="en-GB"
                  format={'dd-MM-yyyy'}
                />
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Relation</Form.Label>
                <Form.Control as="select" onChange={(event)=>changeFMrelation(event.target)} value={FMrelation} defaultValue={FMrelation}>
                  {modalRelationMasterData.map((item,i) => 
                    <option value={item.id} key={i}>{item.name}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="1" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Relation</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={'Of'}
                />
              </Form.Group>
              <Form.Group as={Col} md="5" controlId="exampleForm.ControlSelect2">
                <Form.Label className="formLabel">Relation With</Form.Label>
                <Form.Control as="select" onChange={(event)=>changeFMrelationWith(event.target)} value={FMrelationWithValue} defaultValue={FMrelationWithValue}>
                  {relationJsonData.map((item,i) => 
                    <option value={item.id} className={item.fullname} title={item.fullname} id={item.fullname} label={item.fullname} key={i}>{item.fullname}</option>
                  )}
                  {/* <option value='hello'>hello</option>
                  <option value='hello1'>hello1</option> */}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Education</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Education"
                  defaultValue={FMEducation}
                  onChange={(event)=>changeFMEducation(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label className="formLabel">Occupation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Occupation"
                  defaultValue={FMOccupation}
                  onChange={(event)=>changeFMOccupation(event.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Marital Status</Form.Label>
                <Form.Control as="select" onChange={(event)=>changeFMMaritalStatus(event.target)} value={FMMaritalStatus} defaultValue={FMMaritalStatus}>
                  {maritalStatusData.map((item,i) => 
                    <option value={item.id} key={i}>{item.name}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label className="formLabel">Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobile Number"
                  defaultValue={FMmob}
                  value={FMmob}
                  onChange={(event)=>changeFMmob(event.target.value)}
                  maxLength={10}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                 <Form.Label className="formLabel">Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  defaultValue={FMEmail}
                  value={FMEmail}
                  onChange={(event)=>changeFMEmail(event.target.value)}
                />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            { modalCheckBoxValue && 
              <Form.Row>
                <Col style={{ fontWeight: 'bold' }}>Add company details</Col>
              </Form.Row>
            }
            { modalCheckBoxValue &&  
              <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label className="formLabel">Company name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Company name"
                  defaultValue={FMCompany}
                  value={FMCompany}
                  onChange={(event)=>changeFMCompany(event.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group> 
              
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label className="formLabel">Office Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Office Address"
                  value={FMOfcadd}
                  defaultValue={FMOfcadd}
                  onChange={(event)=>changeFMOfcadd(event.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md="6" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Office Tel</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Office Telephone"
                  defaultValue={FMOfctel}
                  value={FMOfctel}
                  onChange={(event)=>changeFMOfctel(event.target.value)}
                  maxLength={15}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="exampleForm.ControlSelect1">
                <Form.Label className="formLabel">Office Email</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Office Email"
                  value={FMOfcemail}
                  defaultValue={FMOfcemail}
                  onChange={(event)=>changeFMOfcemail(event.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label className="formLabel">Website</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Website"
                  value={FMOfcwebsite}
                  defaultValue={FMOfcwebsite}
                  onChange={(event)=>changeFMOfcwebsite(event.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              </Form.Row>
            }

            <Form.Row>
              <Form.Group controlId="formBasicCheckbox1">
                <Form.Check type="checkbox" name="checkbox1" label="I have my own business" checked={FMChecked} defaultChecked={FMChecked} onChange={handleModalCheckboxValue}/>
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-center">
              <Button type="submit">{FMsubmitButton}</Button>
              <Button onClick={closeModal} style={{ marginLeft: 5 }}>Cancel</Button>
            </Form.Row>
            </Form>
            </Col>
          </Row>
        </Container>
        </Modal.Body>
      </Modal>
      {showviewData &&
        <div>
          {viewData()}
        </div>
      }
      <Row style={{ display: 'flex', justifyContent: 'center' }}>      
        <div style={{ marginTop:10, marginBottom: 10 }}>      
          { showAddButton && <Button onClick={submitForm}>Submit Form</Button> }
          {/* <Button style={{ marginLeft: 5 }} onClick={handleShow}>Add Member</Button> */}
          {showAddButton && <Button style={{ marginLeft: 5 }} onClick={handleShow}>Add Member</Button>}
        </div>
      </Row>
    </Container>
  </div>
  );
}